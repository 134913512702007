var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useState, useEffect } from 'react';
import { Pane, TextField, Select, Button, Row, Col, TextArea, MessageBanner, Accordion, AccordionSet, Datepicker, Checkbox } from '@folio/stripes/components';
import { useHistory, useParams } from 'react-router-dom';
import { useOkapiKy } from '@folio/stripes/core';
import { useQuery } from 'react-query';
var fetchPublisherData = function (id, ky) { return __awaiter(void 0, void 0, void 0, function () {
    var response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, ky.get("publishers/".concat(id))];
            case 1:
                response = _a.sent();
                if (!response.ok) {
                    throw new Error("Error: ".concat(response.statusText));
                }
                return [2 /*return*/, response.json()];
        }
    });
}); };
var PublisherEdit = function () {
    var _a, _b, _c, _d, _e, _f, _g;
    var id = useParams().id;
    var history = useHistory();
    var ky = useOkapiKy();
    var _h = useQuery(['publisher', id], function () { return fetchPublisherData(id, ky); }, { enabled: !!id }), publisherData = _h.data, error = _h.error, isLoading = _h.isLoading;
    var _j = useState({
        id: '',
        name: '',
        nameSuffix: '',
        qualifier: '',
        alternativeNames: [],
        legalForm: 'LEGAL',
        companyNumber: '',
        taxNumber: '',
        birthDate: '',
        mainAddress: undefined,
        mailingAddress: undefined,
        residentialMunicipality: '',
        residentialRegion: { id: '', name: '' },
        contacts: [],
        guarantors: [],
        comment: '',
        internalComment: '',
        origin: 'AGENCY',
        status: 'REQUEST',
        createTime: new Date().toISOString(),
        dataConfirmationTime: new Date().toISOString(),
    }), formValues = _j[0], setFormValues = _j[1];
    var _k = useState({}), errors = _k[0], setErrors = _k[1];
    var _l = useState({ message: '', show: false, type: undefined }), messageBanner = _l[0], setMessageBanner = _l[1];
    var _m = useState({
        general: true,
        address: true,
        contacts: true,
        alternativeNames: true,
        comments: true,
    }), accordionStatus = _m[0], setAccordionStatus = _m[1];
    var _o = useState(false), showMailingAddress = _o[0], setShowMailingAddress = _o[1];
    var _p = useState(true), expandAll = _p[0], setExpandAll = _p[1]; // State to track whether all sections are expanded or collapsed
    useEffect(function () {
        if (publisherData) {
            setFormValues(publisherData);
        }
    }, [publisherData]);
    var handleInputChange = function (e) {
        var _a;
        var _b = e.target, name = _b.name, value = _b.value;
        setFormValues(__assign(__assign({}, formValues), (_a = {}, _a[name] = value, _a)));
    };
    var handleAddressChange = function (e, type) {
        var _a, _b;
        var _c = e.target, name = _c.name, value = _c.value;
        setFormValues(__assign(__assign({}, formValues), (_a = {}, _a[type] = __assign(__assign({}, (formValues[type] || {})), (_b = {}, _b[name] = value, _b)), _a)));
    };
    var handleContactChange = function (index, e) {
        var _a;
        var _b = e.target, name = _b.name, value = _b.value;
        var contacts = __spreadArray([], (formValues.contacts || []), true);
        contacts[index] = __assign(__assign({}, (contacts[index] || {})), (_a = {}, _a[name] = value, _a));
        setFormValues(__assign(__assign({}, formValues), { contacts: contacts }));
    };
    var handleAlternativeNameChange = function (index, e) {
        var _a;
        var _b = e.target, name = _b.name, value = _b.value;
        var alternativeNames = __spreadArray([], (formValues.alternativeNames || []), true);
        alternativeNames[index] = __assign(__assign({}, (alternativeNames[index] || {})), (_a = {}, _a[name] = value, _a));
        setFormValues(__assign(__assign({}, formValues), { alternativeNames: alternativeNames }));
    };
    var addContact = function () {
        setFormValues(__assign(__assign({}, formValues), { contacts: __spreadArray(__spreadArray([], (formValues.contacts || []), true), [{ type: '', value: '' }], false) }));
    };
    var removeContact = function (index) {
        var contacts = __spreadArray([], (formValues.contacts || []), true);
        contacts.splice(index, 1);
        setFormValues(__assign(__assign({}, formValues), { contacts: contacts }));
    };
    var addAlternativeName = function () {
        setFormValues(__assign(__assign({}, formValues), { alternativeNames: __spreadArray(__spreadArray([], (formValues.alternativeNames || []), true), [{ name: '', nameSuffix: '', nonPublic: false }], false) }));
    };
    var removeAlternativeName = function (index) {
        var alternativeNames = __spreadArray([], (formValues.alternativeNames || []), true);
        alternativeNames.splice(index, 1);
        setFormValues(__assign(__assign({}, formValues), { alternativeNames: alternativeNames }));
    };
    var validateForm = function () {
        var newErrors = {};
        if (!formValues.name)
            newErrors.name = 'Název je povinný.';
        (formValues.contacts || []).forEach(function (contact, index) {
            if (!contact.type) {
                newErrors["contactType".concat(index)] = 'Typ kontaktu je povinný.';
            }
            if (!contact.value) {
                newErrors["contactValue".concat(index)] = 'Hodnota kontaktu je povinná.';
            }
        });
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };
    var handleSubmit = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var jsonData, response, error_1, errorMessage;
        var _a, _b, _c;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0:
                    e.preventDefault();
                    if (!validateForm()) {
                        return [2 /*return*/];
                    }
                    jsonData = __assign(__assign({}, formValues), { mainAddress: formValues.mainAddress || undefined, mailingAddress: showMailingAddress ? formValues.mailingAddress : undefined, residentialRegion: formValues.residentialRegion || undefined, alternativeNames: ((_a = formValues.alternativeNames) === null || _a === void 0 ? void 0 : _a.map(function (altName) { return ({
                            name: altName.name,
                            nameSuffix: altName.nameSuffix,
                            type: altName.type,
                            nonPublic: altName.nonPublic,
                        }); })) || [], contacts: ((_b = formValues.contacts) === null || _b === void 0 ? void 0 : _b.map(function (contact) { return ({
                            type: contact.type,
                            value: contact.value,
                        }); })) || [], guarantors: ((_c = formValues.guarantors) === null || _c === void 0 ? void 0 : _c.map(function (guarantor) { return (__assign({}, guarantor)); })) || [] });
                    console.log('Submitting data:', JSON.stringify(jsonData, null, 2));
                    _d.label = 1;
                case 1:
                    _d.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, ky.put("publishers/".concat(id), {
                            json: jsonData,
                            headers: {
                                'Content-Type': 'application/json',
                                'x-okapi-tenant': 'tritius',
                            },
                        })];
                case 2:
                    response = _d.sent();
                    if (!response.ok) {
                        throw new Error("Error: ".concat(response.statusText));
                    }
                    // Show success message
                    setMessageBanner({ message: 'Nakladatel byl úspěšně aktualizován!', show: true, type: 'success' });
                    setTimeout(function () { return setMessageBanner(__assign(__assign({}, messageBanner), { show: false })); }, 3000); // Hide after 3 seconds
                    return [3 /*break*/, 4];
                case 3:
                    error_1 = _d.sent();
                    errorMessage = "Do\u0161lo k chyb\u011B p\u0159i aktualizaci nakladatele: ".concat(error_1.message);
                    console.error('Error updating publisher:', errorMessage);
                    // Show error message
                    setMessageBanner({ message: errorMessage, show: true, type: 'error' });
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var toggleAccordion = function (section) {
        setAccordionStatus(function (prevStatus) {
            var _a;
            return (__assign(__assign({}, prevStatus), (_a = {}, _a[section] = !prevStatus[section], _a)));
        });
    };
    var expandAllSections = function () {
        setAccordionStatus({
            general: true,
            address: true,
            contacts: true,
            alternativeNames: true,
            comments: true,
        });
        setExpandAll(true);
    };
    var collapseAllSections = function () {
        setAccordionStatus({
            general: false,
            address: false,
            contacts: false,
            alternativeNames: false,
            comments: false,
        });
        setExpandAll(false);
    };
    var toggleExpandCollapseAll = function () {
        if (expandAll) {
            collapseAllSections();
        }
        else {
            expandAllSections();
        }
    };
    if (isLoading)
        return React.createElement("div", null, "Na\u010D\u00EDt\u00E1n\u00ED...");
    if (error)
        return React.createElement("div", null, "Chyba p\u0159i na\u010D\u00EDt\u00E1n\u00ED dat nakladatele");
    var footer = (React.createElement(Row, { style: { padding: '20px' } },
        " ",
        React.createElement(Col, { xs: 6 },
            React.createElement(Button, { buttonStyle: "default", onClick: function () { return history.goBack(); }, fullWidth: true }, "Zru\u0161it")),
        React.createElement(Col, { xs: 6 },
            React.createElement(Button, { buttonStyle: "primary", type: "submit", onClick: handleSubmit, fullWidth: true }, "Ulo\u017Eit"))));
    return (React.createElement(Pane, { defaultWidth: "fill", paneTitle: "\u00DAprava nakladatele", footer: footer },
        messageBanner.show && (React.createElement(MessageBanner, { type: messageBanner.type, style: {
                position: 'fixed',
                bottom: '20px',
                right: '20px',
                zIndex: 9999,
                width: '300px',
            } }, messageBanner.message)),
        React.createElement(Row, null,
            React.createElement(Col, { xs: 12, style: { display: 'flex', justifyContent: 'flex-end' } },
                React.createElement(Button, { onClick: toggleExpandCollapseAll, buttonStyle: "default" }, expandAll ? 'Sbalit všechny' : 'Rozbalit všechny'))),
        React.createElement("form", { onSubmit: handleSubmit },
            React.createElement(AccordionSet, null,
                React.createElement(Accordion, { label: "Obecn\u00E9 informace", id: "general-information", open: accordionStatus.general, onToggle: function () { return toggleAccordion('general'); } },
                    React.createElement(Row, null,
                        React.createElement(Col, { xs: 12, md: 3 },
                            React.createElement(TextField, { label: "I\u010CO", name: "companyNumber", value: formValues.companyNumber || '', onChange: handleInputChange })),
                        React.createElement(Col, { xs: 12, md: 3 },
                            React.createElement(TextField, { label: "N\u00E1zev", name: "name", value: formValues.name || '', onChange: handleInputChange, required: true, error: errors.name })),
                        React.createElement(Col, { xs: 12, md: 3 },
                            React.createElement(TextField, { label: "Dopln\u011Bk k n\u00E1zvu", name: "nameSuffix", value: formValues.nameSuffix || '', onChange: handleInputChange }))),
                    React.createElement(Row, null,
                        React.createElement(Col, { xs: 12, md: 3 },
                            React.createElement(TextField, { label: "Kvalifik\u00E1tor", name: "qualifier", value: formValues.qualifier || '', onChange: handleInputChange })),
                        React.createElement(Col, { xs: 12, md: 3 },
                            React.createElement(Select, { label: "Pr\u00E1vn\u00ED forma", name: "legalForm", value: formValues.legalForm || 'LEGAL', dataOptions: [
                                    { label: 'Právnická', value: 'LEGAL' },
                                    { label: 'Fyzická', value: 'PHYSICAL' },
                                ], onChange: handleInputChange })),
                        React.createElement(Col, { xs: 12, md: 3 },
                            React.createElement(Datepicker, { label: "Datum narozen\u00ED", value: formValues.birthDate || '', onChange: function (e, formattedDate, dateString) {
                                    setFormValues(__assign(__assign({}, formValues), { birthDate: dateString || '' }));
                                }, backendDateStandard: "YYYY-MM-DD" // Format for date to backend (ISO format)
                                , dateFormat: "DD/MM/YYYY" // Display date format (European)
                             })))),
                React.createElement(Accordion, { label: "Adresa s\u00EDdla", id: "main-address", open: accordionStatus.address, onToggle: function () { return toggleAccordion('address'); } },
                    React.createElement(Row, null,
                        React.createElement(Col, { xs: 12, md: 3 },
                            React.createElement(TextField, { label: "Ulice a \u010D\u00EDslo", name: "street", value: ((_a = formValues.mainAddress) === null || _a === void 0 ? void 0 : _a.street) || '', onChange: function (e) { return handleAddressChange(e, 'mainAddress'); }, disabled: true })),
                        React.createElement(Col, { xs: 12, md: 3 },
                            React.createElement(TextField, { label: "PS\u010C", name: "postalCode", value: ((_b = formValues.mainAddress) === null || _b === void 0 ? void 0 : _b.postalCode) || '', onChange: function (e) { return handleAddressChange(e, 'mainAddress'); }, disabled: true })),
                        React.createElement(Col, { xs: 12, md: 3 },
                            React.createElement(TextField, { label: "Obec", name: "municipality", value: ((_c = formValues.mainAddress) === null || _c === void 0 ? void 0 : _c.municipality) || '', onChange: function (e) { return handleAddressChange(e, 'mainAddress'); }, disabled: true })),
                        React.createElement(Col, { xs: 12, md: 3 },
                            React.createElement(TextField, { label: "S\u00EDdeln\u00ED obec", name: "residentialMunicipality", value: formValues.residentialMunicipality || '', onChange: handleInputChange, disabled: true }))),
                    React.createElement(Row, null,
                        React.createElement(Col, { xs: 12, md: 3 },
                            React.createElement(TextField, { label: "S\u00EDdeln\u00ED kraj", name: "residentialRegion", value: ((_d = formValues.residentialRegion) === null || _d === void 0 ? void 0 : _d.name) || '', onChange: handleInputChange, disabled: true }))),
                    React.createElement(Row, null,
                        React.createElement(Col, { xs: 12 },
                            React.createElement(Checkbox, { label: "Pokud je adresa s\u00EDdla jin\u00E1 ne\u017E doru\u010Dovac\u00ED adresa", checked: showMailingAddress, onChange: function () { return setShowMailingAddress(!showMailingAddress); } }))),
                    showMailingAddress && (React.createElement(React.Fragment, null,
                        React.createElement("h3", null, "Doru\u010Dovac\u00ED adresa"),
                        React.createElement(Row, null,
                            React.createElement(Col, { xs: 12, md: 3 },
                                React.createElement(TextField, { label: "Ulice a \u010D\u00EDslo", name: "street", value: ((_e = formValues.mailingAddress) === null || _e === void 0 ? void 0 : _e.street) || '', onChange: function (e) { return handleAddressChange(e, 'mailingAddress'); } })),
                            React.createElement(Col, { xs: 12, md: 3 },
                                React.createElement(TextField, { label: "PS\u010C", name: "postalCode", value: ((_f = formValues.mailingAddress) === null || _f === void 0 ? void 0 : _f.postalCode) || '', onChange: function (e) { return handleAddressChange(e, 'mailingAddress'); } })),
                            React.createElement(Col, { xs: 12, md: 3 },
                                React.createElement(TextField, { label: "Obec", name: "municipality", value: ((_g = formValues.mailingAddress) === null || _g === void 0 ? void 0 : _g.municipality) || '', onChange: function (e) { return handleAddressChange(e, 'mailingAddress'); } })))))),
                React.createElement(Accordion, { label: "Kontakty", id: "contacts", open: accordionStatus.contacts, onToggle: function () { return toggleAccordion('contacts'); } },
                    React.createElement(Row, null,
                        React.createElement(Col, { xs: 12 },
                            (formValues.contacts || []).map(function (contact, index) { return (React.createElement("div", { key: index },
                                React.createElement(Row, null,
                                    React.createElement(Col, { xs: 12, md: 4 },
                                        React.createElement(Select, { label: "Typ kontaktu", name: "type", value: contact.type || '', dataOptions: [
                                                { label: 'Mobil', value: 'PHONE' },
                                                { label: 'Email', value: 'EMAIL' },
                                                { label: 'Web', value: 'WEB' },
                                                { label: 'Data Box', value: 'DATABOX' },
                                            ], onChange: function (e) { return handleContactChange(index, e); } })),
                                    React.createElement(Col, { xs: 12, md: 6 },
                                        React.createElement(TextField, { label: "Hodnota kontaktu", name: "value", value: contact.value || '', onChange: function (e) { return handleContactChange(index, e); }, required: true, error: errors["contactValue".concat(index)] })),
                                    React.createElement(Col, { xs: 12, md: 2 },
                                        React.createElement(Button, { buttonStyle: "danger", onClick: function () { return removeContact(index); } }, "Smazat"))))); }),
                            React.createElement(Button, { onClick: addContact }, "P\u0159idat kontakt")))),
                React.createElement(Accordion, { label: "Alternativn\u00ED n\u00E1zvy", id: "alternative-names", open: accordionStatus.alternativeNames, onToggle: function () { return toggleAccordion('alternativeNames'); } },
                    React.createElement(Row, null,
                        React.createElement(Col, { xs: 12 },
                            (formValues.alternativeNames || []).map(function (alternativeName, index) { return (React.createElement("div", { key: index },
                                React.createElement(Row, null,
                                    React.createElement(Col, { xs: 12, md: 4 },
                                        React.createElement(TextField, { label: "N\u00E1zev", name: "name", value: alternativeName.name || '', onChange: function (e) { return handleAlternativeNameChange(index, e); } })),
                                    React.createElement(Col, { xs: 12, md: 4 },
                                        React.createElement(TextField, { label: "Dopln\u011Bk n\u00E1zvu", name: "nameSuffix", value: alternativeName.nameSuffix || '', onChange: function (e) { return handleAlternativeNameChange(index, e); } })),
                                    React.createElement(Col, { xs: 12, md: 2 },
                                        React.createElement(Button, { buttonStyle: "danger", onClick: function () { return removeAlternativeName(index); } }, "Smazat"))))); }),
                            React.createElement(Button, { onClick: addAlternativeName }, "P\u0159idat alternativn\u00ED n\u00E1zev")))),
                React.createElement(Accordion, { label: "Intern\u00ED koment\u00E1\u0159", id: "internal-comment", open: accordionStatus.comments, onToggle: function () { return toggleAccordion('comments'); } },
                    React.createElement(Row, null,
                        React.createElement(Col, { xs: 12 },
                            React.createElement(TextArea, { label: "Intern\u00ED koment\u00E1\u0159", name: "internalComment", value: formValues.internalComment || '', onChange: handleInputChange }))))))));
};
export default PublisherEdit;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useState } from 'react';
import { Row, Col, TextField, Select, Datepicker, Button, Checkbox, TextArea, Pane, Accordion, AccordionSet } from '@folio/stripes/components'; // Adjust the import paths based on your actual component paths
var MyPublisherRegister = function () {
    var _a = useState(true), expandAll = _a[0], setExpandAll = _a[1];
    var _b = useState(false), showMailingAddress = _b[0], setShowMailingAddress = _b[1];
    var _c = useState({
        general: true,
        address: true,
        publicContacts: true,
        garantContact: true,
        contacts: true,
        book: true,
    }), accordionStatus = _c[0], setAccordionStatus = _c[1];
    var _d = useState({
        isbn: '',
        ismn: '',
        planning: '',
        ico: '',
        name: '',
        nameSuffix: '',
        legalForm: '',
        taxNumber: '',
        birthDate: '',
        mainAddress: { street: '', postalCode: '', municipality: '' },
        publishAddressConsent: false,
        mailingAddress: { street: '', postalCode: '', municipality: '' },
        mailingAddressConsent: false,
        contacts: [],
        contactPerson: {
            titlesBefore: '',
            firstName: '',
            lastName: '',
            titlesAfter: '',
            email: ''
        },
        additionalContacts: [],
        bookDetails: {
            title: '',
            subtitle: '',
            volumeNumber: '',
            partName: '',
            authorFirstName: '',
            authorLastName: '',
            publicationDate: '',
            editionOrder: '',
            coPublication: '',
            publicationFormat: '',
            eBookFormat: '',
            url: '',
            mediaVersion: '',
            note: ''
        },
        date: '',
        filledBy: '',
        commitment: '',
    }), formValues = _d[0], setFormValues = _d[1];
    var handleInputChange = function (e) {
        var _a;
        var _b = e.target, name = _b.name, value = _b.value;
        setFormValues(__assign(__assign({}, formValues), (_a = {}, _a[name] = value, _a)));
    };
    var handleAddressChange = function (e, type) {
        var _a, _b;
        var _c = e.target, name = _c.name, value = _c.value;
        setFormValues(__assign(__assign({}, formValues), (_a = {}, _a[type] = __assign(__assign({}, formValues[type]), (_b = {}, _b[name] = value, _b)), _a)));
    };
    var handleContactChange = function (index, e) {
        var _a;
        var _b = e.target, name = _b.name, value = _b.value;
        var updatedContacts = __spreadArray([], formValues.contacts, true);
        updatedContacts[index] = __assign(__assign({}, updatedContacts[index]), (_a = {}, _a[name] = value, _a));
        setFormValues(__assign(__assign({}, formValues), { contacts: updatedContacts }));
    };
    var removeContact = function (index) {
        var updatedContacts = formValues.contacts.filter(function (_, i) { return i !== index; });
        setFormValues(__assign(__assign({}, formValues), { contacts: updatedContacts }));
    };
    var addContact = function () {
        setFormValues(__assign(__assign({}, formValues), { contacts: __spreadArray(__spreadArray([], formValues.contacts, true), [{ type: '', value: '' }], false) }));
    };
    var handleAdditionalContactChange = function (index, e) {
        var _a;
        var _b = e.target, name = _b.name, value = _b.value;
        var updatedContacts = __spreadArray([], formValues.additionalContacts, true);
        updatedContacts[index] = __assign(__assign({}, updatedContacts[index]), (_a = {}, _a[name] = value, _a));
        setFormValues(__assign(__assign({}, formValues), { additionalContacts: updatedContacts }));
    };
    var removeAdditionalContact = function (index) {
        var updatedContacts = formValues.additionalContacts.filter(function (_, i) { return i !== index; });
        setFormValues(__assign(__assign({}, formValues), { additionalContacts: updatedContacts }));
    };
    var addAdditionalContact = function () {
        setFormValues(__assign(__assign({}, formValues), { additionalContacts: __spreadArray(__spreadArray([], formValues.additionalContacts, true), [{ type: '', value: '' }], false) }));
    };
    var expandAllSections = function () {
        setAccordionStatus({
            general: true,
            address: true,
            publicContacts: true,
            garantContact: true,
            contacts: true,
            book: true,
        });
        setExpandAll(true);
    };
    var collapseAllSections = function () {
        setAccordionStatus({
            general: false,
            address: false,
            publicContacts: false,
            garantContact: false,
            contacts: false,
            book: false,
        });
        setExpandAll(false);
    };
    var toggleExpandCollapseAll = function () {
        if (expandAll) {
            collapseAllSections();
        }
        else {
            expandAllSections();
        }
    };
    return (React.createElement(Pane, { defaultWidth: 'fill', paneTitle: "Registrace nakladatele" },
        React.createElement(Row, null,
            React.createElement(Col, { xs: 12, style: { display: 'flex', justifyContent: 'flex-end' } },
                React.createElement(Button, { onClick: toggleExpandCollapseAll, buttonStyle: "default" }, expandAll ? 'Sbalit všechny' : 'Rozbalit všechny'))),
        React.createElement("form", null,
            React.createElement(AccordionSet, null,
                React.createElement(Accordion, { label: "Z\u00E1kladn\u00ED informace", open: accordionStatus.general, onToggle: function () { return setAccordionStatus(__assign(__assign({}, accordionStatus), { general: !accordionStatus.general })); } },
                    React.createElement(Row, null,
                        React.createElement(Col, { xs: 12, md: 6 },
                            React.createElement(TextField, { label: "ISBN - standardn\u00ED \u010D\u00EDslo knihy", name: "isbn", value: formValues.isbn, onChange: handleInputChange })),
                        React.createElement(Col, { xs: 12, md: 6 },
                            React.createElement(TextField, { label: "ISMN - standardn\u00ED \u010D\u00EDslo hudebniny", name: "ismn", value: formValues.ismn, onChange: handleInputChange }))),
                    React.createElement(Row, null,
                        React.createElement(Col, { xs: 12, md: 6 },
                            React.createElement(Select, { label: "Pl\u00E1nuji", name: "planning", value: formValues.planning, dataOptions: [
                                    { label: 'Vydat knihu/hudebninu jednorázově', value: 'one-time' },
                                    { label: 'Vydávat knihy/hudebniny soustavně', value: 'ongoing' }
                                ], onChange: handleInputChange })),
                        React.createElement(Col, { xs: 12, md: 6 },
                            React.createElement(TextField, { label: "I\u010CO", name: "ico", value: formValues.ico, onChange: handleInputChange }))),
                    React.createElement(Row, null,
                        React.createElement(Col, { xs: 12, md: 6 },
                            React.createElement(TextField, { label: "N\u00E1zev", name: "name", value: formValues.name, onChange: handleInputChange })),
                        React.createElement(Col, { xs: 12, md: 6 },
                            React.createElement(TextField, { label: "Dopln\u011Bk k n\u00E1zvu", name: "nameSuffix", value: formValues.nameSuffix, onChange: handleInputChange }))),
                    React.createElement(Row, null,
                        React.createElement(Col, { xs: 12, md: 6 },
                            React.createElement(Select, { label: "Pr\u00E1vn\u00ED forma", name: "legalForm", value: formValues.legalForm, dataOptions: [
                                    { label: 'Právnická osoba', value: 'legal' },
                                    { label: 'Fyzická osoba', value: 'physical' }
                                ], onChange: handleInputChange })),
                        React.createElement(Col, { xs: 12, md: 6 },
                            React.createElement(TextField, { label: "DI\u010C", name: "taxNumber", value: formValues.taxNumber, onChange: handleInputChange }))),
                    React.createElement(Row, null,
                        React.createElement(Col, { xs: 12, md: 6 },
                            React.createElement(Datepicker, { label: "Datum narozen\u00ED", name: "birthDate", value: formValues.birthDate, onChange: function (e, formattedDate, dateString) {
                                    setFormValues(__assign(__assign({}, formValues), { birthDate: dateString || '' }));
                                }, backendDateStandard: "YYYY-MM-DD" })))),
                React.createElement(Accordion, { label: "Adresa", open: accordionStatus.address, onToggle: function () { return setAccordionStatus(__assign(__assign({}, accordionStatus), { address: !accordionStatus.address })); } },
                    React.createElement(Row, null,
                        React.createElement(Col, { xs: 12, md: 6 },
                            React.createElement(TextField, { label: "Ulice a \u010D\u00EDslo", name: "street", value: formValues.mainAddress.street, onChange: function (e) { return handleAddressChange(e, 'mainAddress'); } })),
                        React.createElement(Col, { xs: 12, md: 6 },
                            React.createElement(TextField, { label: "PS\u010C", name: "postalCode", value: formValues.mainAddress.postalCode, onChange: function (e) { return handleAddressChange(e, 'mainAddress'); } })),
                        React.createElement(Col, { xs: 12, md: 6 },
                            React.createElement(TextField, { label: "Obec", name: "municipality", value: formValues.mainAddress.municipality, onChange: function (e) { return handleAddressChange(e, 'mainAddress'); } })),
                        React.createElement(Col, { xs: 12, md: 6 },
                            React.createElement(Checkbox, { label: "Souhlas se zve\u0159ejn\u011Bn\u00EDm", name: "publishAddressConsent", checked: formValues.publishAddressConsent, onChange: function (e) { return setFormValues(__assign(__assign({}, formValues), { publishAddressConsent: e.target.checked })); } }))),
                    React.createElement(Checkbox, { label: "Li\u0161\u00ED se koresponden\u010Dn\u00ED adresa?", checked: showMailingAddress, onChange: function () { return setShowMailingAddress(!showMailingAddress); } }),
                    showMailingAddress && (React.createElement(React.Fragment, null,
                        React.createElement(Row, null,
                            React.createElement(Col, { xs: 12 },
                                React.createElement(TextField, { label: "Koresponden\u010Dn\u00ED adresa", name: "street", value: formValues.mailingAddress.street, disabled: true, onChange: function (e) { return handleAddressChange(e, 'mailingAddress'); } })),
                            React.createElement(Col, { xs: 6 },
                                React.createElement(TextField, { label: "PS\u010C", name: "postalCode", value: formValues.mailingAddress.postalCode, disabled: true, onChange: function (e) { return handleAddressChange(e, 'mailingAddress'); } })),
                            React.createElement(Col, { xs: 6 },
                                React.createElement(TextField, { label: "M\u011Bsto", name: "municipality", value: formValues.mailingAddress.municipality, disabled: true, onChange: function (e) { return handleAddressChange(e, 'mailingAddress'); } })))))),
                React.createElement(Accordion, { label: "Kontakty pro ve\u0159ejnost", open: accordionStatus.publicContacts, onToggle: function () { return setAccordionStatus(__assign(__assign({}, accordionStatus), { publicContacts: !accordionStatus.publicContacts })); } },
                    React.createElement("h3", null, "Kontakty pro ve\u0159ejnost (telefon, e-mail, web, datov\u00E1 schr\u00E1nka)"),
                    formValues.contacts.map(function (contact, index) { return (React.createElement(Row, { key: index },
                        React.createElement(Col, { xs: 12, md: 6 },
                            React.createElement(Select, { label: "Typ", name: "type", value: contact.type, dataOptions: [
                                    { label: 'Telefon', value: 'phone' },
                                    { label: 'E-mail', value: 'email' },
                                    { label: 'Web', value: 'web' },
                                    { label: 'Datová schránka', value: 'data' }
                                ], onChange: function (e) { return handleContactChange(index, e); } })),
                        React.createElement(Col, { xs: 12, md: 6 },
                            React.createElement(TextField, { label: "Hodnota", name: "value", value: contact.value, onChange: function (e) { return handleContactChange(index, e); } })),
                        React.createElement(Col, { xs: 12, md: 12 },
                            React.createElement(Button, { onClick: function () { return removeContact(index); } }, "Odstranit kontakt")))); }),
                    React.createElement(Button, { onClick: addContact }, "P\u0159idat kontakt")),
                React.createElement(Accordion, { label: "Garant - kontaktn\u00ED osoba pro agenturu", open: accordionStatus.garantContact, onToggle: function () { return setAccordionStatus(__assign(__assign({}, accordionStatus), { garantContact: !accordionStatus.garantContact })); } },
                    React.createElement(Row, null,
                        React.createElement(Col, { xs: 12, md: 6 },
                            React.createElement(TextField, { label: "Tituly p\u0159ed jm\u00E9nem", name: "titlesBefore", value: formValues.contactPerson.titlesBefore, onChange: function (e) { return setFormValues(__assign(__assign({}, formValues), { contactPerson: __assign(__assign({}, formValues.contactPerson), { titlesBefore: e.target.value }) })); } })),
                        React.createElement(Col, { xs: 12, md: 6 },
                            React.createElement(TextField, { label: "Jm\u00E9no", name: "firstName", value: formValues.contactPerson.firstName, onChange: function (e) { return setFormValues(__assign(__assign({}, formValues), { contactPerson: __assign(__assign({}, formValues.contactPerson), { firstName: e.target.value }) })); } })),
                        React.createElement(Col, { xs: 12, md: 6 },
                            React.createElement(TextField, { label: "P\u0159\u00EDjmen\u00ED", name: "lastName", value: formValues.contactPerson.lastName, onChange: function (e) { return setFormValues(__assign(__assign({}, formValues), { contactPerson: __assign(__assign({}, formValues.contactPerson), { lastName: e.target.value }) })); } })),
                        React.createElement(Col, { xs: 12, md: 6 },
                            React.createElement(TextField, { label: "Tituly za jm\u00E9nem", name: "titlesAfter", value: formValues.contactPerson.titlesAfter, onChange: function (e) { return setFormValues(__assign(__assign({}, formValues), { contactPerson: __assign(__assign({}, formValues.contactPerson), { titlesAfter: e.target.value }) })); } })),
                        React.createElement(Col, { xs: 12, md: 6 },
                            React.createElement(TextField, { label: "E-mail", name: "email", value: formValues.contactPerson.email, onChange: function (e) { return setFormValues(__assign(__assign({}, formValues), { contactPerson: __assign(__assign({}, formValues.contactPerson), { email: e.target.value }) })); } }))),
                    formValues.additionalContacts.map(function (contact, index) { return (React.createElement(Row, { key: index },
                        React.createElement(Col, { xs: 12, md: 6 },
                            React.createElement(Select, { label: "Typ", name: "type", value: contact.type, dataOptions: [
                                    { label: 'Telefon', value: 'phone' },
                                    { label: 'E-mail', value: 'email' },
                                    { label: 'Web', value: 'web' },
                                    { label: 'Datová schránka', value: 'data' }
                                ], onChange: function (e) { return handleAdditionalContactChange(index, e); } })),
                        React.createElement(Col, { xs: 12, md: 6 },
                            React.createElement(TextField, { label: "Hodnota", name: "value", value: contact.value, onChange: function (e) { return handleAdditionalContactChange(index, e); } })),
                        React.createElement(Col, { xs: 12, md: 12 },
                            React.createElement(Button, { onClick: function () { return removeAdditionalContact(index); } }, "Odstranit kontakt")))); }),
                    React.createElement(Button, { onClick: addAdditionalContact }, "P\u0159idat kontakt")),
                React.createElement(Accordion, { label: "\u00DAdaje o knize / hudebnin\u011B", open: accordionStatus.book, onToggle: function () { return setAccordionStatus(__assign(__assign({}, accordionStatus), { book: !accordionStatus.book })); } },
                    React.createElement(Row, null,
                        React.createElement(Col, { xs: 12, md: 6 },
                            React.createElement(TextField, { label: "N\u00E1zev", name: "title", value: formValues.bookDetails.title, onChange: function (e) { return setFormValues(__assign(__assign({}, formValues), { bookDetails: __assign(__assign({}, formValues.bookDetails), { title: e.target.value }) })); } })),
                        React.createElement(Col, { xs: 12, md: 6 },
                            React.createElement(TextField, { label: "Podn\u00E1zev", name: "subtitle", value: formValues.bookDetails.subtitle, onChange: function (e) { return setFormValues(__assign(__assign({}, formValues), { bookDetails: __assign(__assign({}, formValues.bookDetails), { subtitle: e.target.value }) })); } }))),
                    React.createElement(Row, null,
                        React.createElement(Col, { xs: 12, md: 4 },
                            React.createElement(TextField, { label: "\u010C\u00EDslo d\u00EDlu, svazku", name: "volumeNumber", value: formValues.bookDetails.volumeNumber, onChange: function (e) { return setFormValues(__assign(__assign({}, formValues), { bookDetails: __assign(__assign({}, formValues.bookDetails), { volumeNumber: e.target.value }) })); } })),
                        React.createElement(Col, { xs: 12, md: 4 },
                            React.createElement(TextField, { label: "N\u00E1zev \u010D\u00E1sti", name: "partName", value: formValues.bookDetails.partName, onChange: function (e) { return setFormValues(__assign(__assign({}, formValues), { bookDetails: __assign(__assign({}, formValues.bookDetails), { partName: e.target.value }) })); } })),
                        React.createElement(Col, { xs: 12, md: 4 },
                            React.createElement(TextField, { label: "Jm\u00E9no autora", name: "authorFirstName", value: formValues.bookDetails.authorFirstName, onChange: function (e) { return setFormValues(__assign(__assign({}, formValues), { bookDetails: __assign(__assign({}, formValues.bookDetails), { authorFirstName: e.target.value }) })); } })),
                        React.createElement(Col, { xs: 12, md: 4 },
                            React.createElement(TextField, { label: "P\u0159\u00EDjmen\u00ED autora", name: "authorLastName", value: formValues.bookDetails.authorLastName, onChange: function (e) { return setFormValues(__assign(__assign({}, formValues), { bookDetails: __assign(__assign({}, formValues.bookDetails), { authorLastName: e.target.value }) })); } })),
                        React.createElement(Col, { xs: 12, md: 4 },
                            React.createElement(Datepicker, { label: "Datum vyd\u00E1n\u00ED", name: "publicationDate", value: formValues.bookDetails.publicationDate, onChange: function (e, formattedDate, dateString) {
                                    setFormValues(__assign(__assign({}, formValues), { bookDetails: __assign(__assign({}, formValues.bookDetails), { publicationDate: dateString || '' }) }));
                                }, backendDateStandard: "YYYY-MM-DD" })),
                        React.createElement(Col, { xs: 12, md: 4 },
                            React.createElement(TextField, { label: "Po\u0159ad\u00ED vyd\u00E1n\u00ED", name: "editionOrder", value: formValues.bookDetails.editionOrder, onChange: function (e) { return setFormValues(__assign(__assign({}, formValues), { bookDetails: __assign(__assign({}, formValues.bookDetails), { editionOrder: e.target.value }) })); } })),
                        React.createElement(Col, { xs: 12, md: 4 },
                            React.createElement(TextField, { label: "Vyd\u00E1no v koedici s", name: "coPublication", value: formValues.bookDetails.coPublication, onChange: function (e) { return setFormValues(__assign(__assign({}, formValues), { bookDetails: __assign(__assign({}, formValues.bookDetails), { coPublication: e.target.value }) })); } })),
                        React.createElement(Col, { xs: 12, md: 4 },
                            React.createElement(Select, { label: "V jak\u00E9 podob\u011B chcete publikaci vydat", name: "publicationFormat", value: formValues.bookDetails.publicationFormat, dataOptions: [
                                    { label: 'Tištěná publikace', value: 'printed' },
                                    { label: 'E-kniha', value: 'ebook' },
                                    { label: 'Audio kniha', value: 'audiobook' }
                                ], onChange: function (e) { return setFormValues(__assign(__assign({}, formValues), { bookDetails: __assign(__assign({}, formValues.bookDetails), { publicationFormat: e.target.value }) })); } })),
                        React.createElement(Col, { xs: 12, md: 4 },
                            React.createElement(Select, { label: "Form\u00E1t e-knihy / e-hudebniny", name: "eBookFormat", value: formValues.bookDetails.eBookFormat, dataOptions: [
                                    { label: 'pdf', value: 'pdf' },
                                    { label: 'epub', value: 'epub' },
                                    { label: 'mobi', value: 'mobi' },
                                    { label: 'Jiný formát', value: 'other' }
                                ], onChange: function (e) { return setFormValues(__assign(__assign({}, formValues), { bookDetails: __assign(__assign({}, formValues.bookDetails), { eBookFormat: e.target.value }) })); } })),
                        React.createElement(Col, { xs: 12, md: 4 },
                            React.createElement(TextField, { label: "URL", name: "url", value: formValues.bookDetails.url, onChange: function (e) { return setFormValues(__assign(__assign({}, formValues), { bookDetails: __assign(__assign({}, formValues.bookDetails), { url: e.target.value }) })); } })),
                        React.createElement(Col, { xs: 12, md: 4 },
                            React.createElement(Select, { label: "Verze na datov\u00E9m nosi\u010Di", name: "mediaVersion", value: formValues.bookDetails.mediaVersion, dataOptions: [
                                    { label: 'CD-ROM', value: 'cd-rom' },
                                    { label: 'DVD-ROM', value: 'dvd-rom' },
                                    { label: 'Bez nosiče', value: 'none' }
                                ], onChange: function (e) { return setFormValues(__assign(__assign({}, formValues), { bookDetails: __assign(__assign({}, formValues.bookDetails), { mediaVersion: e.target.value }) })); } })),
                        React.createElement(Col, { xs: 12, md: 4 })),
                    React.createElement(Row, null,
                        React.createElement(Col, { xs: 12 },
                            React.createElement(TextArea, { label: "Odesl\u00E1n\u00EDm formul\u00E1\u0159e se nakladatel zavazuje...", name: "commitment", value: formValues.commitment, onChange: function (e) { return setFormValues(__assign(__assign({}, formValues), { commitment: e.target.value })); } }))),
                    React.createElement(Row, null,
                        React.createElement(Col, { xs: 12 },
                            React.createElement(TextField, { label: "Datum", name: "date", value: formValues.date, onChange: function (e) { return setFormValues(__assign(__assign({}, formValues), { date: e.target.value })); } })),
                        React.createElement(Col, { xs: 12 },
                            React.createElement(TextField, { label: "Vyplnil, jm\u00E9no a p\u0159\u00EDjmen\u00ED", name: "filledBy", value: formValues.filledBy, onChange: function (e) { return setFormValues(__assign(__assign({}, formValues), { filledBy: e.target.value })); } })))),
                React.createElement(Button, { type: "submit" }, "Odeslat")))));
};
export default MyPublisherRegister;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState } from 'react';
import { Row, Col, Checkbox, Button, AccordionSet, Accordion } from '@folio/stripes/components';
import { useHistory } from 'react-router-dom';
var PublisherRegistrationsDetail = function (_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
    var publisher = _a.publisher;
    var history = useHistory();
    var _o = useState({
        general: true,
        address: true,
        contacts: true,
        comments: true,
        timestamps: true,
    }), accordionStatus = _o[0], setAccordionStatus = _o[1];
    var _p = useState(true), expandAll = _p[0], setExpandAll = _p[1];
    var formatDate = function (dateString) {
        if (!dateString)
            return 'N/A';
        var date = new Date(dateString);
        var day = date.getDate().toString().padStart(2, '0');
        var month = (date.getMonth() + 1).toString().padStart(2, '0');
        var year = date.getFullYear();
        var hours = date.getHours().toString().padStart(2, '0');
        var minutes = date.getMinutes().toString().padStart(2, '0');
        return "".concat(day, ".").concat(month, ".").concat(year, " ").concat(hours, ":").concat(minutes);
    };
    var renderField = function (label, value) { return (React.createElement(Row, { style: { marginBottom: '25px' } },
        React.createElement(Col, { xs: 12 },
            React.createElement("div", { style: { marginBottom: '5px' } },
                React.createElement("strong", { style: { fontSize: '1.1em', paddingRight: '5px' } }, label)),
            React.createElement("div", null, value || 'N/A')))); };
    var hasMailingAddress = ((_b = publisher.mailingAddress) === null || _b === void 0 ? void 0 : _b.street) || ((_c = publisher.mailingAddress) === null || _c === void 0 ? void 0 : _c.postalCode) || ((_d = publisher.mailingAddress) === null || _d === void 0 ? void 0 : _d.municipality);
    var hasContacts = publisher.contacts && publisher.contacts.length > 0;
    var toggleAccordion = function (section) {
        setAccordionStatus(function (prevState) {
            var _a;
            return (__assign(__assign({}, prevState), (_a = {}, _a[section] = !prevState[section], _a)));
        });
    };
    var toggleExpandCollapseAll = function () {
        if (expandAll) {
            setAccordionStatus({
                general: false,
                address: false,
                contacts: false,
                comments: false,
                timestamps: false,
            });
        }
        else {
            setAccordionStatus({
                general: true,
                address: true,
                contacts: true,
                comments: true,
                timestamps: true,
            });
        }
        setExpandAll(!expandAll);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("h1", null,
            publisher.name,
            ", ",
            publisher.nameSuffix),
        React.createElement(Row, null,
            React.createElement(Col, { xs: 12, style: { display: 'flex', justifyContent: 'flex-end' } },
                React.createElement(Button, { onClick: toggleExpandCollapseAll, buttonStyle: "default" }, expandAll ? 'Sbalit všechny' : 'Rozbalit všechny'))),
        React.createElement(AccordionSet, null,
            React.createElement(Accordion, { id: "general", label: "Obecn\u00E9 informace", open: accordionStatus.general, onToggle: function () { return toggleAccordion('general'); } },
                React.createElement(Row, null,
                    React.createElement(Col, { xs: 12, md: 6 }, renderField('IČO', publisher.companyNumber)),
                    React.createElement(Col, { xs: 12, md: 6 }, renderField('Datum narození', publisher.birthDate))),
                React.createElement(Row, null,
                    React.createElement(Col, { xs: 12, md: 6 }, renderField('DIČ', publisher.taxNumber)),
                    React.createElement(Col, { xs: 12, md: 6 }, renderField('Název *', publisher.name))),
                React.createElement(Row, null,
                    React.createElement(Col, { xs: 12, md: 6 }, renderField('Doplněk k názvu', publisher.nameSuffix)),
                    React.createElement(Col, { xs: 12, md: 6 }, renderField('Kvalifikátor', publisher.qualifier))),
                React.createElement(Row, null,
                    React.createElement(Col, { xs: 12, md: 6 }, renderField('Právní forma', publisher.legalForm)))),
            React.createElement(Accordion, { id: "address", label: "Adresa", open: accordionStatus.address, onToggle: function () { return toggleAccordion('address'); } },
                React.createElement("h3", null, "Adresa s\u00EDdla"),
                React.createElement(Row, null,
                    React.createElement(Col, { xs: 12, md: 4 }, renderField('Ulice a číslo *', (_e = publisher.mainAddress) === null || _e === void 0 ? void 0 : _e.street)),
                    React.createElement(Col, { xs: 12, md: 4 }, renderField('PSČ *', (_f = publisher.mainAddress) === null || _f === void 0 ? void 0 : _f.postalCode)),
                    React.createElement(Col, { xs: 12, md: 4 }, renderField('Obec *', (_g = publisher.mainAddress) === null || _g === void 0 ? void 0 : _g.municipality))),
                hasMailingAddress && (React.createElement(React.Fragment, null,
                    React.createElement("h3", null, "Doru\u010Dovac\u00ED adresa"),
                    React.createElement(Row, null,
                        React.createElement(Col, { xs: 12, md: 4 }, renderField('Ulice a číslo *', (_h = publisher.mailingAddress) === null || _h === void 0 ? void 0 : _h.street)),
                        React.createElement(Col, { xs: 12, md: 4 }, renderField('PSČ *', (_j = publisher.mailingAddress) === null || _j === void 0 ? void 0 : _j.postalCode)),
                        React.createElement(Col, { xs: 12, md: 4 }, renderField('Obec *', (_k = publisher.mailingAddress) === null || _k === void 0 ? void 0 : _k.municipality))))),
                React.createElement(Row, null,
                    React.createElement(Col, { xs: 12, md: 6 }, renderField('Sídelní obec', publisher.residentialMunicipality)),
                    React.createElement(Col, { xs: 12, md: 6 }, renderField('Sídelní kraj', (_l = publisher.residentialRegion) === null || _l === void 0 ? void 0 : _l.name)))),
            React.createElement(Accordion, { id: "contacts", label: "Kontakty", open: accordionStatus.contacts, onToggle: function () { return toggleAccordion('contacts'); } }, hasContacts ? ((_m = publisher.contacts) === null || _m === void 0 ? void 0 : _m.map(function (contact, index) { return (React.createElement(Row, { key: index },
                React.createElement(Col, { xs: 12, md: 4 }, renderField('Typ', contact.type)),
                React.createElement(Col, { xs: 12, md: 4 }, renderField('Hodnota', contact.value)))); })) : (React.createElement(Row, null,
                React.createElement(Col, { xs: 12 },
                    React.createElement("div", null, "\u017D\u00E1dn\u00E9 kontakty k zobrazen\u00ED"))))),
            React.createElement(Accordion, { id: "comments", label: "Intern\u00ED pozn\u00E1mka", open: accordionStatus.comments, onToggle: function () { return toggleAccordion('comments'); } },
                React.createElement(Row, null,
                    React.createElement(Col, { xs: 12 }, renderField('Interní poznámka', publisher.internalComment)))),
            React.createElement(Accordion, { id: "timestamps", label: "\u010Casy", open: accordionStatus.timestamps, onToggle: function () { return toggleAccordion('timestamps'); } },
                React.createElement(Row, null,
                    React.createElement(Col, { xs: 12, md: 6 }, renderField('Čas registrace', formatDate(publisher.createTime))),
                    React.createElement(Col, { xs: 12, md: 6 }, renderField('Čas aktivace', formatDate(publisher.activationTime)))),
                React.createElement(Row, null,
                    React.createElement(Col, { xs: 12, md: 6 }, renderField('Čas potvrzení údajů', formatDate(publisher.dataConfirmationTime))),
                    React.createElement(Col, { xs: 12, md: 6 }, renderField('Čas uzavření', formatDate(publisher.closeTime)))))),
        React.createElement(Row, null,
            React.createElement(Col, { xs: 12 },
                React.createElement(Checkbox, { label: "Souhlas se zve\u0159ejn\u011Bn\u00EDm", checked: false, disabled: true })))));
};
export default PublisherRegistrationsDetail;

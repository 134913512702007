import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import PublisherList from './PublisherList';
import PublisherEdit from './PublisherEdit';
import PublisherCreate from './PublisherCreate';
import PublisherClose from './PublisherClose';
function Publishers() {
    var path = useRouteMatch().path; // useRouteMatch to get the base path
    var cleanPath = path.replace('/*', '');
    return (React.createElement(Switch, null,
        React.createElement(Route, { exact: true, path: cleanPath, component: PublisherList }),
        React.createElement(Route, { path: "".concat(cleanPath, "/create"), component: PublisherCreate }),
        React.createElement(Route, { path: "".concat(cleanPath, "/edit/:id"), component: PublisherEdit }),
        React.createElement(Route, { path: "".concat(cleanPath, "/close/:id"), component: PublisherClose })));
}
export default Publishers;

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useQuery, QueryClient, QueryClientProvider } from 'react-query';
import { useOkapiKy } from '@folio/stripes/core';
import { Pane, IconButton } from '@folio/stripes/components';
import TableComponent from '../../components/TableComponent';
import NavComponent from '../../components/NavComponent';
import PublisherRegistrationsDetail from './PublisherRegistrationsDetail'; // Adjusted import path
import { StripesConnectedSource } from '@folio/stripes/smart-components';
var queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: 0,
            cacheTime: 0,
            refetchOnWindowFocus: false,
            refetchOnMount: true,
        },
    },
});
var PublisherRegistrationsList = function () {
    var history = useHistory();
    var ky = useOkapiKy();
    var _a = useState(null), selectedPublisherRegister = _a[0], setSelectedPublisherRegister = _a[1];
    var _b = useState({
        name: '',
        ico: '',
        startDate: undefined,
        endDate: undefined,
    }), filters = _b[0], setFilters = _b[1];
    var _c = useState(true), filterPaneIsVisible = _c[0], setFilterPaneIsVisible = _c[1];
    var _d = useState(''), searchTerm = _d[0], setSearchTerm = _d[1];
    var fetchPublisherUpdates = function () { return __awaiter(void 0, void 0, void 0, function () {
        var response, data, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 3, , 4]);
                    return [4 /*yield*/, ky.get('publishers', {
                            headers: {
                                'Cache-Control': 'no-cache, no-store, must-revalidate',
                                Pragma: 'no-cache',
                                Expires: '0',
                            },
                        })];
                case 1:
                    response = _a.sent();
                    return [4 /*yield*/, response.json()];
                case 2:
                    data = _a.sent();
                    return [2 /*return*/, data.publishers];
                case 3:
                    error_1 = _a.sent();
                    console.error('Fetch error:', error_1);
                    throw error_1;
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var isAnyFilterActive = function () {
        return true;
    };
    var _e = useQuery(['publishers', filters], fetchPublisherUpdates, {
        enabled: isAnyFilterActive(),
        staleTime: 0,
        cacheTime: 0,
    }), _f = _e.data, publisherRegistrationsData = _f === void 0 ? [] : _f, isLoading = _e.isLoading, isError = _e.isError;
    var toggleFilterPane = function () { return setFilterPaneIsVisible(function (prev) { return !prev; }); };
    var statusTranslations = {
        REQUEST: 'Žádost',
        REJECTED: 'Odmítnuto',
        ACTIVE: 'Aktivní',
        CLOSING: 'Uzavírání',
        CLOSED: 'Uzavřeno',
    };
    var columns = [
        {
            name: 'Nakladatel',
            key: 'name',
            clickable: true,
            onClick: function (item) { return setSelectedPublisherRegister(item); },
        },
        {
            name: 'Doplňek k názvu',
            key: 'nameSuffix',
        },
        {
            name: 'Kvalifikátor',
            key: 'qualifier',
        },
        {
            name: 'IČO',
            key: 'companyNumber',
        },
        {
            name: 'Datum narození',
            key: 'birthData',
        },
        {
            name: 'Stav',
            key: 'status',
            formatter: function (item) { return statusTranslations[item.status]; }, // Use Czech translations here
        },
    ];
    var source = new StripesConnectedSource({}, null);
    source.update = function () { };
    source.records = function () { return publisherRegistrationsData; };
    source.resultCount = function () { return publisherRegistrationsData.length; };
    source.totalCount = function () { return publisherRegistrationsData.length; };
    source.pending = function () { return isLoading; };
    source.loaded = function () { return isAnyFilterActive(); };
    source.failure = function () { return isError ? { message: 'Error loading data' } : null; };
    source.failureMessage = function () { return 'Error loading data'; };
    source.fetchMore = function () { };
    source.fetchByBrowsePoint = function () { };
    source.fetchOffset = function () { };
    source.successfulMutations = function () { return []; };
    return (React.createElement(QueryClientProvider, { client: queryClient },
        React.createElement("div", { style: { display: 'flex', height: '93.5vh' } },
            filterPaneIsVisible && (React.createElement(Pane, { defaultWidth: "25%", paneTitle: "Navigace", lastMenu: filterPaneIsVisible ? React.createElement(IconButton, { icon: "caret-left", onClick: toggleFilterPane, "aria-label": filterPaneIsVisible ? 'Hide filters' : 'Show filters', style: { marginLeft: 'auto' } }) : React.createElement(React.Fragment, null) },
                React.createElement("div", { style: { display: 'flex', flexDirection: 'column' } },
                    React.createElement(NavComponent, null)))),
            React.createElement(Pane, { defaultWidth: filterPaneIsVisible ? 'fill' : '100%', paneTitle: "Seznam registrac\u00ED nakladatele", paneSub: publisherRegistrationsData.length > 0 ? "Nalezeno ".concat(publisherRegistrationsData.length, " z\u00E1znam\u016F") : "Zadejte vyhledávací kritéria pro zahájení vyhledávání", firstMenu: !filterPaneIsVisible ? React.createElement(IconButton, { icon: "caret-right", onClick: toggleFilterPane, "aria-label": filterPaneIsVisible ? 'Hide filters' : 'Show filters', style: { marginLeft: 'auto' } }) : React.createElement(React.Fragment, null) },
                React.createElement(TableComponent, { data: publisherRegistrationsData, columns: columns, pageSize: 30, hasDataFetched: isAnyFilterActive, filterPaneIsVisible: filterPaneIsVisible, toggleFilterPane: toggleFilterPane, source: source, searchTerm: searchTerm })),
            selectedPublisherRegister ? (React.createElement(Pane, { defaultWidth: "35%", paneTitle: "Detail Registrace nakladatele", onClose: function () { return setSelectedPublisherRegister(null); }, dismissible: true }, selectedPublisherRegister ? (React.createElement(PublisherRegistrationsDetail, { publisher: selectedPublisherRegister })) : (React.createElement("div", null, "No details available")))) : null)));
};
export default PublisherRegistrationsList;
